const defaultConf = require('./config-default');
const BO_URL = 'cigre2024-data.ms-dev.mobile-spot.com';
const NODE_BACKEND_DOMAIN = 'cigre-node-backend.ms-dev.mobile-spot.com';
//const LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/cigre/cigre2024-data.ms-dev.mobile-spot.com',
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  //'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  //'legacy', // 'www'
  BO_SSH_PORT: '43122',
  //'43122'

  DEPLOY_PATH: '/home/legacy/cigre/cigre2024.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-15',
  GOOGLE_ANALYTICS_ID_V4: 'G-389525709',
  projectId: '669066963522',
  // FCM sender id
  appId: 'EDE73-87D9C',
  // pushwoosh appid

  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  POLL: {
    URL: "http://".concat(NODE_BACKEND_DOMAIN, "/poll")
  },
  CONTRIBUTIONS_FEED: {
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/contributions-feed")
  },
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  SSO: {
    SSO_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/sso-request")
  },
  SOCIAL: {
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social") // `${LOCAL_NODE_BACKEND_DOMAIN}/social`,
  },
  SECURED_PDF: {
    SECURED_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/secured-pdf-viewer") // `${LOCAL_NODE_BACKEND_DOMAIN}/secured-pdf-viewer`,
  },
  PUBLIC_PDF: {
    PUBLIC_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/public-pdf-viewer")
  }
});