import { AIRCRAFT_PAGE_KEY, EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, EXPERIENCE_ITEM_PAGE_KEY, FEDLIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY, LIST_PAGE_KEY, NEWPRODUCT_PAGE_KEY, RESTAURANT_PAGE_KEY, SERVICE_PAGE_KEY, DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';
import { formatDate, formatTime, formatDateAndTime } from 'src/core/Lang';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { openUrl } from 'src/core/util/JsTools';
import { sendPageView } from 'src/core/analytics/GoogleAnalyticsHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isAndroid, isIOS } from 'src/core/util/browser';
import { DEFAULT_IMAGE } from './ficheConfig';
import { DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_BRANDS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_DOCUNITS, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_PAPERS, DATA_TYPE_PLACES, DATA_TYPE_GMAP_PLACES, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_FAVORITE_POSITIONS, DATA_TYPE_GOLDENSUMMIT, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_USERPROJECTS, DATA_TYPE_USERPROJECT_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_CONTESTS, DATA_TYPE_CONTEST_CATEGORIES, CATEGORIES_MAPPING, getSpeakerTitle, getSpeakerSubtitle, DATA_TYPE_MEETING_CONTACTS, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_STORES } from './dataConfig';
const LOG_PREF = '[listConfig] ';

/**
 * Hide items counter in category list
 * @type {Boolean}
 */
export const HIDE_CATEGORY_ITEMS_COUNTER = false;
export const ROW_BACKGROUND_COLOR_ON_CLICK = '#efefef';

/**
 * Display alphabetical index if row count if above
 * @type {Number}
 */
export const LIST_SIDEINDEX_THRESHOLD = 50;

/**
 * Specific threshold for lists of groups (e.g events)
 * @type {Number}
 */
export const LIST_OF_GROUPS_SIDEINDEX_THRESHOLD = 20;

/**
 * List displays only the items beginning with the current alphabetical index
 * @type {Number}
 */
export const ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID = 200;
export const ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS = 700;
export const ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB = 1000;
export const getAlphabeticalListByIndexThreshold = () => {
  if (isAndroid()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID;
  }
  if (isIOS()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS;
  }
  return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB;
};

// Typical use case: search results
export const HIGHLIGHTED_PROPS = ['text', 'textMinor'];
export const DISABLE_FAVORITE_ICON = false;
export const DISABLE_FAVORITE_BY_DATATYPE = [];

/**
 * TypeBar is automatically closed when items count is higher than this
 * @see DataListDialog
 * @type {Number}
 */
export const DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE = 5;
export const HEIGHT_LIST_ELEMENT = '50px';

/**
 * Ability to modify the side of the alphabetical index
 * @return {string}
 */
export const getSideIndexPosition = () =>
// On desktop with app displayed in two columns, put the index on the left side
// to prevent list scrollTop to be modified when cursor moves from left column to right column.
TwoColumnsModeManager.isEnabled() && global.isCordovaContext === false ? 'left' : 'right';
function hasPlaces(row) {
  return Array.isArray(row.places) && row.places.length > 0;
}
function serviceHasContent(service) {
  return !!(service.description || service.phone || service.website || service.email);
}
const stringifyIfSetOrNull = value => value ? String(value) : null;

/**
 * Define here the `props` (~ attributes) an element of a list will receive.
 * Every helper function receives as parameter the full object
 *----------------------------------------------------------------------
 * See app-react/src/components/list/ListElement.js for available props
 *----------------------------------------------------------------------
 */
export function elementPropsGetters(dataType, pageKey) {
  switch (dataType) {
    // NB: generic props are set by List component:
    //       - 'data-id' (data item id)
    //       - 'data-type'

    case DATA_TYPE_PLACES:
      return {
        key: place => "".concat(place.id, "-").concat(place.memberType, "-").concat(place.memberId),
        text: place => String(place.memberTitle),
        textMinor: place => String(place.label),
        textMinorBis: place => String(place.info || ''),
        'data-member-type': place => String(place.memberType),
        'data-member-id': place => String(place.memberId),
        'data-member-original-id': place => String(place.memberOriginalId)
      };
    case DATA_TYPE_ANIMATIONS:
      return {
        text: anim => String(anim.title),
        'data-original-id': anim => String(anim.original_id),
        'data-contextual-place-id': anim => String(anim.contextualPlaceId)
      };
    case DATA_TYPE_BRANDS:
      return {
        text: brand => String(brand.title),
        textMinor: brand => {
          var _brand$lump;
          return (_brand$lump = brand.lump) === null || _brand$lump === void 0 ? void 0 : _brand$lump.subtitle;
        },
        'data-original-id': brand => String(brand.original_id)
      };
    case DATA_TYPE_SERVICES:
      return {
        text: service => String(service.title),
        textMinor: service => {
          var _service$lump;
          return (_service$lump = service.lump) === null || _service$lump === void 0 ? void 0 : _service$lump.subtitle;
        },
        // image: service => service.logo_file_name,
        'data-link': service => service.lump && service.lump.link || null,
        'data-original-id': service => String(service.original_id),
        'data-contextual-place-id': service => service.contextualPlaceId,
        'data-has-places': service => hasPlaces(service),
        'data-no-content': service => serviceHasContent(service) !== true,
        isClickable: service => hasPlaces(service) || serviceHasContent(service) // default: true
      };
    case DATA_TYPE_EXHIBITORS:
      return {
        'data-original-id': exh => String(exh.original_id),
        'data-contextual-place-id': exh => exh.contextualPlaceId,
        text: exh => String(exh.title),
        textMinor: exh => {
          var _exh$lump, _exh$lump2;
          return exh.lump && (_exh$lump = exh.lump) !== null && _exh$lump !== void 0 && _exh$lump.subtitle ? (_exh$lump2 = exh.lump) === null || _exh$lump2 === void 0 ? void 0 : _exh$lump2.subtitle : null;
        },
        textStyle: exh => exh.lump && exh.lump.color ? {
          color: exh.lump.color
        } : null,
        className: exh => {
          const classes = [];
          if (exh.lump) {
            if (exh.lump.bold === '1') {
              classes.push('emphasised');
            }
          }
          // to be completed
          return classes.join(' ');
        },
        image: exh => exh.lump && exh.lump.logolist ? exh.logo_file_name : null
      };
    case DATA_TYPE_STORES:
      return {
        'data-original-id': exh => String(exh.original_id),
        'data-contextual-place-id': exh => exh.contextualPlaceId,
        text: exh => String(exh.title),
        textMinor: exh => {
          var _exh$lump3, _exh$lump4;
          return exh.lump && (_exh$lump3 = exh.lump) !== null && _exh$lump3 !== void 0 && _exh$lump3.subtitle ? (_exh$lump4 = exh.lump) === null || _exh$lump4 === void 0 ? void 0 : _exh$lump4.subtitle : null;
        },
        textStyle: exh => exh.lump && exh.lump.color ? {
          color: exh.lump.color
        } : null,
        className: exh => {
          const classes = [];
          if (exh.lump) {
            if (exh.lump.bold === '1') {
              classes.push('emphasised');
            }
          }
          // to be completed
          return classes.join(' ');
        },
        image: exh => exh.lump && exh.lump.logolist ? exh.logo_file_name : null
      };
    case DATA_TYPE_AIRCRAFTS:
      return {
        text: aircraft => String(aircraft.title),
        textMinor: aircraft => {
          var _aircraft$lump, _aircraft$lump2;
          return aircraft.lump && (_aircraft$lump = aircraft.lump) !== null && _aircraft$lump !== void 0 && _aircraft$lump.subtitle ? (_aircraft$lump2 = aircraft.lump) === null || _aircraft$lump2 === void 0 ? void 0 : _aircraft$lump2.subtitle : null;
        },
        'data-original-id': aircraft => String(aircraft.original_id),
        'data-contextual-place-id': aircraft => String(aircraft.contextualPlaceId)
      };
    case DATA_TYPE_RESTAURANTS:
      return {
        text: restau => String(restau.title),
        textMinor: restau => {
          var _restau$lump;
          return (_restau$lump = restau.lump) === null || _restau$lump === void 0 ? void 0 : _restau$lump.subtitle;
        },
        'data-original-id': restau => String(restau.original_id)
      };
    case DATA_TYPE_SPEAKERS:
      return {
        text: speaker => getSpeakerTitle(speaker),
        textMinor: speaker => {
          var _speaker$lump, _speaker$lump2;
          return speaker.lump && (_speaker$lump = speaker.lump) !== null && _speaker$lump !== void 0 && _speaker$lump.subtitle ? (_speaker$lump2 = speaker.lump) === null || _speaker$lump2 === void 0 ? void 0 : _speaker$lump2.subtitle : null;
        },
        // textMinor: speaker => getSpeakerSubtitle(speaker),
        ["data-".concat(DATA_TYPE_EVENTS)]: speaker => speaker.lump ? speaker.lump[DATA_TYPE_EVENTS] : [],
        image: speaker => speaker.photo,
        underImage: speaker => speaker.logo,
        useForIndexLetter: speaker => speaker.lastname
        /* isClickable: speaker => (
                    pageKey !== EVENT_PAGE_KEY
                    && Array.isArray(speaker.lump[DATA_TYPE_EVENTS])
                    && speaker.lump[DATA_TYPE_EVENTS].length > 0
                ), */
      };
    case DATA_TYPE_EVENTS:
      return {
        'data-id': event => stringifyIfSetOrNull(event.isTaigaEvent ? event.dbId : event.id),
        'data-original-id': event => stringifyIfSetOrNull(event.original_id),
        'data-contextual-place-id': event => stringifyIfSetOrNull(event.contextualPlaceId),
        event: event => ({
          start_date: event.start_date,
          start_time: event.start_time,
          end_date: event.end_date,
          end_time: event.end_time
        }),
        text: event => String(event.title),
        // textMinor: event => event.event_place_text ? event.event_place_text : null,
        textMinor: event => {
          var _event$lump, _event$lump2;
          return event.lump && (_event$lump = event.lump) !== null && _event$lump !== void 0 && _event$lump.subtitle ? (_event$lump2 = event.lump) === null || _event$lump2 === void 0 ? void 0 : _event$lump2.subtitle : null;
        },
        isClickable: event => !(event.isTaigaEvent && !event.dbId)
      };
    case DATA_TYPE_CONTESTS:
      return {
        'data-id': event => stringifyIfSetOrNull(event.id),
        'data-original-id': event => stringifyIfSetOrNull(event.original_id),
        event: event => ({
          start_date: event.start_date,
          start_time: event.start_time,
          end_date: event.end_date,
          end_time: event.end_time
        }),
        text: event => String(event.title),
        textMinor: event => {
          var _event$lump3, _event$lump4;
          return event.lump && (_event$lump3 = event.lump) !== null && _event$lump3 !== void 0 && _event$lump3.subtitle ? (_event$lump4 = event.lump) === null || _event$lump4 === void 0 ? void 0 : _event$lump4.subtitle : null;
        }
      };
    case DATA_TYPE_PAPERS:
      return {
        text: np => String(np.title),
        textMinor: np => {
          var _np$lump, _np$lump2;
          return np.lump && (_np$lump = np.lump) !== null && _np$lump !== void 0 && _np$lump.subtitle ? (_np$lump2 = np.lump) === null || _np$lump2 === void 0 ? void 0 : _np$lump2.subtitle : null;
        }
      };
    case DATA_TYPE_FLIGHTS_SCHEDULE:
      return {
        'data-id': flight => flight.id,
        text: flight => flight.aircraft && flight.aircraft.title || 'Unknown aircraft',
        event: flight => {
          const start = flight.time_start.split(':');
          return {
            start_date: Number(flight.date),
            start_time: start.length > 2 ? start.slice(0, 2).join(':') : start.join(':'),
            end_date: Number(flight.date),
            end_time: ''
          };
        },
        textMinor: flight => flight.time_end,
        // aircraft desc is very long
        isClickable: flight => flight.hasAircraft
      };
    case DATA_TYPE_NEWPRODUCTS:
      return {
        text: np => String(np.title),
        'data-url': np => np.lump ? np.lump.URL : '',
        textMinor: np => {
          var _np$lump3, _np$lump4;
          return np.lump && (_np$lump3 = np.lump) !== null && _np$lump3 !== void 0 && _np$lump3.subtitle ? (_np$lump4 = np.lump) === null || _np$lump4 === void 0 ? void 0 : _np$lump4.subtitle : null;
        },
        image: np => np.photo_file_name
      };
    case DATA_TYPE_GOLDENSUMMIT:
      return {
        text: np => String(np.title),
        textMinor: np => np.exhibitor ? String(np.exhibitor.title) : null,
        image: np => np.photo_file_name
      };
    case DATA_TYPE_USERPROJECTS:
      return {
        text: np => String(np.title),
        textMinor: np => {
          var _np$lump5, _np$lump6;
          return np.lump && (_np$lump5 = np.lump) !== null && _np$lump5 !== void 0 && _np$lump5.subtitle ? (_np$lump6 = np.lump) === null || _np$lump6 === void 0 ? void 0 : _np$lump6.subtitle : null;
        },
        image: np => np.photo_file_name
      };
    case DATA_TYPE_JOBOFFERS:
      return {
        data_id: docunit => docunit.id,
        'data-original-id': docunit => docunit.original_id,
        text: docunit => docunit.title,
        textMinor: docunit => {
          var _docunit$lump, _docunit$lump2;
          return docunit.lump && (_docunit$lump = docunit.lump) !== null && _docunit$lump !== void 0 && _docunit$lump.subtitle ? (_docunit$lump2 = docunit.lump) === null || _docunit$lump2 === void 0 ? void 0 : _docunit$lump2.subtitle : null;
        },
        image: docunit => docunit.photo_file_name ? docunit.photo_file_name : null
      };
    case DATA_TYPE_FAVORITE_POSITIONS:
      return {
        'data-id': fav => fav.id,
        text: fav => fav.title
      };
    case DATA_TYPE_DOCUNITS:
      return {
        data_id: docunit => docunit.id,
        'data-original-id': docunit => docunit.original_id,
        text: docunit => docunit.title,
        image: docunit => docunit.image ? docunit.image : null
      };
    case DATA_TYPE_PARTICIPANTS:
    case DATA_TYPE_CONTACTED_PARTICIPANTS:
      return {
        text: p => "<b>".concat(p.civility ? "".concat(p.civility, " ") : '').concat(p.firstName, " ").concat(p.lastName, "</b>"),
        textMinor: p => p.organizationName,
        textMinorBis: p => p.type,
        image: p => p.profilePicture && p.profilePicture.value ? p.profilePicture.value : DEFAULT_IMAGE[DATA_TYPE_PARTICIPANTS],
        useForIndexLetter: p => p.lastName
      };
    case DATA_TYPE_CONTACTS:
      return {
        text: p => "<b>".concat(p.civility ? "".concat(p.civility, " ") : '').concat(p.firstName, " ").concat(p.lastName, "</b>"),
        textMinor: p => formatDateAndTime(p.scanDate),
        textMinorBis: p => p.role,
        image: p => p.profilePicture && p.profilePicture.value ? p.profilePicture.value : DEFAULT_IMAGE[DATA_TYPE_CONTACTS],
        useForIndexLetter: p => p.lastName
      };
    case DATA_TYPE_KLIPSO_LEADS_CONTACTS:
      return {
        leftImageClassName: contact => contact.isSync ? 'kl-list-icon-sync check-icon' : 'kl-list-icon-notsync sync-icon',
        text: contact => contact.Societe ? "".concat(contact.Societe, "<br>") : '',
        textMinor: contact => (contact.Prenom ? "".concat(contact.Prenom, " ") : '') + (contact.Nom ? "<span style=\"text-transform: uppercase;\">".concat(contact.Nom, "</span>") : ''),
        'data-cab': contact => contact.code,
        useForIndexLetter: contact => contact.Societe // use for side index when data is sorted by company
      };
    case DATA_TYPE_GMAP_PLACES:
      return {
        text: np => String(np.title),
        textMinor: np => {
          var _np$lump7, _np$lump8;
          return np.lump && (_np$lump7 = np.lump) !== null && _np$lump7 !== void 0 && _np$lump7.subtitle ? (_np$lump8 = np.lump) === null || _np$lump8 === void 0 ? void 0 : _np$lump8.subtitle : null;
        }
      };
    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_STORE_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
    case DATA_TYPE_PAPER_CATEGORIES:
    case DATA_TYPE_USERPROJECT_CATEGORIES:
    case DATA_TYPE_JOBOFFER_CATEGORIES:
    case DATA_TYPE_CONTEST_CATEGORIES:
      return {
        counter: cat => cat.counter,
        text: cat => String(cat.title),
        image: cat => cat.lump && cat.lump.logo,
        textMinor: cat => {
          var _cat$lump;
          return ((_cat$lump = cat.lump) === null || _cat$lump === void 0 ? void 0 : _cat$lump.subtitle) || null;
        }
      };
    case DATA_TYPE_SERVICE_CATEGORIES:
      return {
        text: cat => String(cat.title),
        image: cat => cat.lump && cat.lump.logo,
        textMinor: cat => {
          var _cat$lump2;
          return ((_cat$lump2 = cat.lump) === null || _cat$lump2 === void 0 ? void 0 : _cat$lump2.subtitle) || null;
        }
      };
    case DATA_TYPE_GOLDENSUMMIT_CATEGORIES:
      return {
        /* counter: (cat) => cat.counter,
          text: (cat) => String(cat.title), */
        originaltext: cat => String(cat.title),
        image: cat => cat.lump && cat.lump.logo
        /* textMinor: (cat) => cat.lump?.subtitle || null, */
      };
    case DATA_TYPE_MEETING_CONTACTS:
      return {
        text: p => "<b>".concat(p.civility ? "".concat(p.civility, " ") : '').concat(p.firstName, " ").concat(p.lastName, "</b>"),
        textMinor: p => p.organizationName,
        event: p => ({
          start_date: p.event.start_date,
          start_time: p.event.start_time,
          end_date: p.event.end_date,
          end_time: p.event.end_time
        }),
        image: p => p.profilePicture && p.profilePicture.value ? p.profilePicture.value : DEFAULT_IMAGE[DATA_TYPE_PARTICIPANTS],
        useForIndexLetter: p => p.lastName,
        isClickableWithIcon: () => false
      };
    default:
      console.error("".concat(LOG_PREF, "No helpers specified for type `").concat(dataType, "`"));
  }
}

/**
 * Indicate if user is redirected to map (show POI) when selecting an item
 * @param  {string} dataType
 * @return {boolean}
 */
export function shouldRedirectToMap(dataType) {
  switch (dataType) {
    case DATA_TYPE_BRANDS:
      // Show POI on map
      return true;
    default:
      return false;
  }
}

/**
 * Indicate if user is redirected to map (show POI) when selecting an item
 * @param  {string} dataType
 * @return {boolean}
 */
export function shouldRedirectToExternalLink(dataType) {
  switch (dataType) {
    /*    case DATA_TYPE_NEWPRODUCTS:
      // Show on external link
      return true; */

    default:
      return false;
  }
}

/**
 * Define here what should happen when a note list entry is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */
export function onClickOnNote(liEl, actions, labels, pageKey) {
  actions.showNoteModal(liEl.title, liEl.dataset.id, liEl.dataset.type, liEl);
}

/**
 * Define here what should happen when a list entry (<ListElements />) is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */
export function onClickOnLi(liEl, actions, labels, pageKey) {
  let {
    id,
    type,
    originalId,
    url
  } = liEl.dataset;

  // Auto parse id
  if (/^\d+$/.test(id)) {
    id = parseInt(id, 10);
  }
  switch (type) {
    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_STORE_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_PAPER_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
    case DATA_TYPE_GOLDENSUMMIT_CATEGORIES:
    case DATA_TYPE_USERPROJECT_CATEGORIES:
    case DATA_TYPE_JOBOFFER_CATEGORIES:
    case DATA_TYPE_CONTEST_CATEGORIES:
      actions.categoryClicked(id, type);
      break;
    case DATA_TYPE_AIRCRAFTS:
    case DATA_TYPE_ANIMATIONS:
    case DATA_TYPE_BRANDS:
    case DATA_TYPE_EXHIBITORS:
    case DATA_TYPE_STORES:
    case DATA_TYPE_EVENTS:
    case DATA_TYPE_NEWPRODUCTS:
    case DATA_TYPE_RESTAURANTS:
    case DATA_TYPE_PAPERS:
    case DATA_TYPE_DOCUNITS:
    case DATA_TYPE_PARTICIPANTS:
    case DATA_TYPE_CONTACTS:
    case DATA_TYPE_SPEAKERS:
    case DATA_TYPE_GMAP_PLACES:
    case DATA_TYPE_GOLDENSUMMIT:
    case DATA_TYPE_USERPROJECTS:
    case DATA_TYPE_JOBOFFERS:
      actions.genericItemNavigation(type, id, originalId, url);
      break;
    case DATA_TYPE_CONTACTED_PARTICIPANTS:
      actions.genericItemNavigation(DATA_TYPE_PARTICIPANTS, id, originalId);
      break;
    case DATA_TYPE_CONTESTS:
    case DATA_TYPE_MEETING_CONTACTS:
      actions.genericItemNavigation(type, id, originalId);
      break;
    case DATA_TYPE_SERVICES:
      if (liEl.dataset.link) {
        openUrl(getUrl(liEl.dataset.link, !global.isCordovaContext,
        // use http
        true // absolute
        ));
        sendPageView(liEl.dataset.link);
      } else if (liEl.dataset.noContent === 'true' && liEl.dataset.hasPlaces === 'true') {
        actions.showOnePoiOnMobigeo({
          type,
          id,
          originalId
        });
      } else {
        actions.genericItemNavigation(type, id, originalId);
      }
      break;
    case DATA_TYPE_PLACES:
      const {
        searchType
      } = liEl.parentNode.dataset;
      const textMinor = liEl.querySelector('.text-minor');
      actions.searchedPlaceSelected(searchType, {
        placeId: id,
        type: liEl.dataset.memberType,
        id: liEl.dataset.memberId,
        originalId: liEl.dataset.memberOriginalId,
        text: liEl.getAttribute('title'),
        textMinor: textMinor ? textMinor.textContent : null
      });
      break;
    case DATA_TYPE_FAVORITE_POSITIONS:
      actions.showOnePoiOnMobigeo({
        type,
        id,
        originalId: null
      });
      break;
    case DATA_TYPE_FLIGHTS_SCHEDULE:
      actions.flightScheduleClicked(id);
      break;
    case DATA_TYPE_KLIPSO_LEADS_CONTACTS:
      actions.klipsoLeadsEnableFormScreen({
        code: liEl.dataset.cab
      });
      break;
    default:
      console.error("".concat(LOG_PREF, "Nothing defined for click on type `").concat(type, "`"));
  }
}